import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Mubeen is a fantastic developer! He was quick to learn the structure of a project and be helpful from the start in implementing new features in creative and efficient ways.
                                                    He's a great, responsible and friendly colleague.
                                                    His knowledge in Objective-C was quickly expanded, showing his firm understanding of object oriented syntaxes and logic.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Danilo Silva</span> - iOS Developer - eDerm Systems</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Mubeen is an excellent developer, he communicates frequently and solves all issues, will hire him again!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Ron Sagi</span> - CEO - Blueberry Payments</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Great work, Good communication, respect the deadlines and i loved how he is flexible with the revisions and give his good opinions on how we can improve the app, looking forward working together long term.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Hicham Amrhar</span> - CEO - AskPDF</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Mubeen is a capable developer. I have had the pleasure of working with him on a project where he was able to demonstrate the required skills, going above and beyond to ensure quality delivery. He is an ambitious resource, keen to learn more and grow.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Faizan Shah</span> - Head of Fulfilment - PowerHouse</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I worked with Mubeen on multiple iOS projects. I was impressed by his work ethics due to the fact that he'd surprise me everytime with his extraordinary work quality. He would go extra miles on each project, in short he is the kind of guy who'd literally make your life easier.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Ahmed Tariq</span> - Product Manager - Confidential.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Great developer and is great with time management. Completed tasks upon request.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Mario G.</span> - COO - Routers LLC.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Committed and diligent towards work and task that was required. Many thanks.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Bilal Tahir</span> - COO - Savilles Dry Cleaners Ltd.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/dani.png" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Ron.png" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/hicham.png" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Faizan.png" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Ahmad.png" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Mario.png" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Bilal.png" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
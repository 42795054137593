import React ,{ Component }from "react";
import { FaBars, FaBuffer, FaServicestack } from "react-icons/fa";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiPhone, FiSmartphone, FiServer, FiCloudLightning, FiWatch, FiTerminal, FiUserX } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiSmartphone />,
        title: 'Mobile App Development',
        description: 'I craft seamless mobile experiences that delight users. With a focus on speed, performance, and user-friendly design, my native IOS development ensures a smooth and engaging user experience that keeps users coming back.'
    },
    {
        icon: <FiServer />,
        title: 'Backend Development',
        description: 'A strong backend is the backbone of a successful application. I build robust, scalable, and secure backend solutions using cutting-edge technologies like NodeJS, MongoDB, and Express. My goal is to optimize performance, ensure data integrity, and provide a seamless user experience.'
    },
    {
        icon: <FiTerminal />,
        title: 'AI/ML',
        description: 'Unlock the full potential of your business with artificial intelligence and machine learning. I develop intelligent solutions that learn, adapt, and improve over time, enabling data-driven decision-making, automation, and a competitive edge in the market.'
    },
    {
        icon: <FiTerminal />,
        title: 'Emerging Technologies',
        description: 'Embracing innovation is key to staying ahead of the curve. I stay up-to-date with the latest emerging technologies like Touch ID, Face Recognition, IoT, Smartwatches, VR, and AR. My expertise helps you leverage these game-changers to drive business growth and stay ahead of the competition.'
    },
    {
        icon: <FiWatch />,
        title: 'Watch App Development',
        description: 'Stay active, smart, and fit with wearable technology. I design and develop innovative watch apps that track vital signs, monitor motion, and detect facial and handshakes. My expertise ensures a seamless user experience that motivates you to reach your fitness goals.'
    },
    {
        icon: <FiLayers />,
        title: 'Full Stack Development',
        description: 'I transform ideas into stunning technology products that optimize business processes and drive growth. With a focus on modern applications and universal device compatibility, my full stack development ensures a seamless user experience that looks and performs amazing on any device, anywhere.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;

import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'react-bootstrap';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Awards",
        tab3 = "Experience",
        tab4 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title">IOS App Development - Objective-C - Swift - SwiftUI</h6>
                                                    <ProgressBar now={100} />
                                                    <span className="label">100%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Mobile App Development - React Native</h6>
                                                    <ProgressBar now={85} />
                                                    <span className="label">85%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Backend Development - Node.js - MongoDB</h6>
                                                    <ProgressBar now={75} />
                                                    <span className="label">75%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>


                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">3 Times Star of the Month (PureLogics)<span>- Winner</span></a> 2019 - 2022
                                               </li>
                                               <li>
                                                   <a href="/service">FYP Exhibition in University annual fare. <span>- Winner</span></a> 2015 - 2019
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                   <a href="/service">Senior Mobile Engineer(iOS Team Lead)<span> - VERiPARK</span></a> September 2023 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Senior Software Engineer(iOS Team Lead)<span> - HealthWire</span></a> October 2022 - August 2023
                                               </li>
                                               <li>
                                                   <a href="/service">Senior Software Engineer<span> - PureLogics</span></a> September 2019 - October 2022
                                               </li>
                                               <li>
                                                   <a href="/service">Associate Software Engineer<span> - TecJaunt </span></a> January 2018 - August 2019
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                   <a href="/service">MSC in Applied AI and Data Sciecnes</a><span>Solent University, Southampton, United Kingdom</span> - Continue
                                               </li>
                                               <li>
                                                   <a href="/service">BS(Hons) in Computer Science</a><span>University of Gujrat, Lahore, Pakistan</span> - 2019
                                               </li>
                                               <li>
                                                   <a href="/service">Diploma of Associate Engineering</a><span>Punjab Board Technical Institute, Lahore, Pakistan</span> - 2014
                                               </li>
                                               <li>
                                                   <a href="/service">Matriculation</a><span>Board of Intermediate & Secondary Education, Lahore, Pakistan</span> - 2011
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;